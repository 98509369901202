<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left" label-width="108px">
          <ayl-main title="基本信息" :isCollapse="false">
            <div slot="main" class="p24px">
              <el-form-item label="所属公司" prop="company">
                <el-select v-model="form.company" placeholder="请选择"
                          :disabled="$route.query.status==='已启用'">
                  <el-option v-for="(item, idx) in companyList"
                            :key="idx"
                            :label="item.company"
                            :value="item.companyId"/>
                </el-select>
              </el-form-item>
              <el-form-item label="SIM卡号" prop="simNumber">
                <el-input v-model="form.simNumber" maxlength="14"
                          :disabled="$route.query.type==='detail'||$route.query.type==='edit'"></el-input>
              </el-form-item>
              <el-form-item label="运营商" prop="serviceProvider">
                <el-select v-model="form.serviceProvider" placeholder="请选择"
                          :disabled="$route.query.type==='detail'">
                  <el-option v-for="(item, idx) in $enums.ServiceProvider.list"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="套餐" prop="pricePackage">
                <el-input v-model="form.pricePackage" maxlength="16" :disabled="$route.query.type==='detail'"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" prop="expireDate">
                <el-date-picker
                        :disabled="$route.query.type==='detail'"
                        v-model="form.expireDate"
                        clearable
                        value-format="timestamp"
                        type="date"
                        placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <!--<el-form-item label="SIM状态">-->
                <!--<el-input v-model="status" disabled></el-input>-->
              <!--</el-form-item>-->
              <el-form-item label="提醒天数(天)" prop="alarmDay">
                <el-input v-model="form.alarmDay" clearable placeholder="输入1-30的整数"
                          :disabled="$route.query.type==='detail'"></el-input>
              </el-form-item>
            </div>
          </ayl-main>
          <ayl-main title="其他信息(选填)" :isCollapse="false">
            <div slot="main" class="p24px">
              <!--<el-form-item label="代理人" prop="agent">-->
                <!--<el-input v-model="form.agent" maxlength="32"-->
                          <!--:disabled="$route.query.type==='detail'"></el-input>-->
              <!--</el-form-item>-->
              <!--<el-form-item label="联系方式" prop="agentPhone">-->
                <!--<el-input v-model="form.agentPhone"-->
                          <!--maxlength="13"-->
                          <!--:disabled="$route.query.type==='detail'"></el-input>-->
              <!--</el-form-item>-->
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea"
                          :disabled="$route.query.type==='detail'"
                          maxlength="64"
                          :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容" v-model="form.remark">
                </el-input>
              </el-form-item>
            </div>
          </ayl-main>
        </el-form>
      
    <div class="tac mt40px">
      <el-button type="primary"
                 @click="submitCar"
                 :loading="submitLoading"
                 v-if="$route.query.type!=='detail'">确 认</el-button>
      <el-button style="margin-left: 30px" @click="$router.push('/vehicle-management/sim-management')">返 回</el-button>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const validateSimNumber = (rule, value, callback) => {
        if (!value||value.length>14||value.length<11) return callback(new Error('请输入11—14位SIM卡号'))
        if (!(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请输入11—14位SIM卡号'))
        } else {
          callback()
        }
      }
      const validateAgentPhone = (rule, value, callback) => {
        if(!value) return callback()
        if (value.length<6||value.length>13) return callback(new Error('请输入正确的联系电话'))
        if (!(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请输入正确的联系电话'))
        } else {
          callback()
        }
      }
      const validateAlarmDay = (rule, value, callback) => {
        if (!value) return callback(new Error('请输入提醒天数'))
        if (!(value < 31) || !(value >= 1) || !(/^[0-9]*[1-9][0-9]*$/.test(value))) {
          callback(new Error('请正确输入1-30之间的整数'))
        } else {
          callback()
        }
      }
      return {
        nav: [
          {name: "车辆管理", path: ""},
          {name: "SIM卡管理", path: "/vehicle-management/sim-management"},
          {name: "新增", path: ""},
        ],
        // status: '未启用',
        form: {
          company: null,
          simNumber: null,
          serviceProvider: null,
          pricePackage: null,
          // agent: null,
          // agentPhone: null,
          expireDate: null,
          remark: null,
          alarmDay: null,
        },
        rules: {
          company: {required: true, message: '请选择所属公司', trigger: 'change'},
          simNumber: {required: true, validator: validateSimNumber, trigger: 'blur'},
          serviceProvider: {required: true, message: '请选择运营商', trigger: 'change'},
          alarmDay: {required: true, validator: validateAlarmDay, trigger: 'blur'},
          pricePackage: [{required: true, trigger: 'blur', message: '请输入套餐名称'},
            // {validator: this.$validator.isContainBlank}
            ],
          expireDate: {required: true, message: '请选择时间', trigger: 'change'},
          remark: {message: '请输入2-64位的备注', trigger: 'blur'},
          // agent: {message: '请输入2-32位的代理人', trigger: 'blur', min: 2, max: 32},
          // agentPhone: {trigger: 'blur', validator: validateAgentPhone},
        },
        submitLoading: false,
        companyList: []
      }
    },
    methods: {
      submitCar(){
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            let message = '新增'
            if (this.$route.query.type==='edit'){
              await this.$api.UpdateSimInfo({
                tenantId: this.form.tenantId,
                simInfoVOIOV: this.form
              })
              message = '编辑'
            }else {
              await this.$api.InsertSimInfo({
                simInfoVOIOV: this.form
              })
            }
            this.$notify({
              title: '成功',
              message: message+'SIM信息成功',
              type: 'success'
            });
            this.$router.push('/vehicle-management/sim-management')
          } catch (e){}
          this.submitLoading = false
        })
      }
    },
    async mounted () {
      this.companyList = await this.$api.SearchCompanyList({})

      if (!this.$route.query.type) return
      this.$route.query.type==='edit'?
        this.nav[this.nav.length-1].name='编辑':
        this.nav[this.nav.length-1].name='查看SIM详情'
      this.form = await this.$api.GetSimInfoBySimNumber({
        simNumber: this.$route.query.simNumber
      })
      this.status = this.form.status
    }
  }
</script>

<style lang='sass' scoped>
</style>
